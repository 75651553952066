import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import icalendarPlugin from "@fullcalendar/icalendar";
import dayGridPlugin from "@fullcalendar/daygrid";

import config from "../config.json";
import { useMemo } from "react";

export default function Planning({ credentials, setCredentials }) {
  const handleLogout = () => {
    localStorage.setItem("credentials", null);
    setCredentials(null);
  };
  const currentSchool = useMemo(() => {
    return new URLSearchParams(credentials).get("school");
  }, [credentials]);

  return (
    <div className="relative bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 my-6 sm:mx-auto sm:max-w-[75%] sm:rounded-lg sm:px-10">
      <div className="mx-auto max-w-auto">
        <div className="flex justify-between">
          <h1 className="text-2xl leading-6 font-medium text-gray-900">
            Planning Karl
          </h1>
          <button
            onClick={handleLogout}
            className="group relative flex align-center justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Se déconnecter
          </button>
        </div>
        <div className="divide-y divide-gray-300/50">
          <div className="space-y-6 py-8 text-base leading-7 text-gray-600">
            <p>
              Ce planning est un outil d'aide à la planification de modules dans
              votre école.
              <br />
              Seules vos créneaux sont lisibles. Pour les autres vous aurez une
              indication du lieu et du temps de trajet pour rejoindre votre
              établissement.
              <br />
              <p className="text-red-600">
                Seules les créneaux figés sont affichés, ceux en attente de
                validation ne seront pas affichés.
              </p>
              <p className="font-extrabold text-red-600">
                Les créneaux libres sont donc à titre indicatif et doivent être
                validés par mes soins.
              </p>
            </p>
            <FullCalendar
              locale={"fr"}
              themeSystem="sandstone"
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "timeGridWeek,dayGridMonth",
              }}
              defaultView="dayGridWeek"
              plugins={[dayGridPlugin, timeGridPlugin, icalendarPlugin]}
              events={{
                url: config.API_URL + "/calendarICS?" + credentials,
                format: "ics",
                eventDataTransform: (eventData) => {
                  if (eventData.title.startsWith(currentSchool)) {
                    eventData.color = "green";
                    eventData.textColor = "white";
                    eventData.backgroundColor = "green";
                  } else {
                    eventData.color = "rgb(55, 136, 216)";
                    eventData.textColor = "white";
                    eventData.backgroundColor = "rgb(55, 136, 216)";
                  }
                  return eventData;
                },
              }}
              allDaySlot={false}
              slotMinTime="08:00"
              slotMaxTime="21:00"
              hiddenDays={[0]}
              height="auto"
              buttonText={{
                today: "Aujourd'hui",
                month: "Mois",
                week: "Semaine",
                day: "Jour",
                list: "Liste",
              }}
              weekNumbers={true}
              weekNumberFormat={{ week: "numeric" }}
              eventDidMount={(info) => {
                if (info.event.title.startsWith(currentSchool)) {
                  info.el.classList.add("bg-green-400");
                  info.el.classList.add("text-white");
                }
              }}
              eventTimeFormat={{
                // like '14:30:00'
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

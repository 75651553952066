import Login from "./pages/Login";
import { useState } from "react";
import Planning from "./pages/Planning";

function App() {
  const [credentials, setCredentials] = useState(
    localStorage.getItem("credentials") || null
  );
  return (
    <div className="App">
      {credentials && (
        <Planning credentials={credentials} setCredentials={setCredentials} />
      )}
      {!credentials && <Login onSubmit={setCredentials} />}
    </div>
  );
}

export default App;
